<template>
    <div>
      <price-lists-list></price-lists-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  